import { FC, useCallback } from 'react';
import { useState } from 'react';
import { Button, Col, Collapse, Row } from 'reactstrap';
import styled from 'styled-components';
import { device } from 'utilities/constaint';
import { Container } from 'utilities/container';
import { MobilePadding } from 'utilities/mobile-pad';
import Section from 'utilities/section';
import icon1 from './images/icon1.png';
import icon2 from './images/icon2.png';
const Text1 = styled.div`
  color: #838383;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
`;
const Text2 = styled.div`
  text-align: center;
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 20px;
`;
const MT = styled.div`
  margin-top: 48px;
`;
const CollapseBoardStyle = styled.div`
  display: flex;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 14px;
  box-shadow: 0 0 5px 5px #5655550d;
  .b-content {
    flex: 1;
  }
  .title-desciption {
    font-size: 16px;
    padding-bottom: 32px;
  }
  .title {
    @media ${device.md} {
      height: 80px;
    }
    height: 50px;
    display: grid;
    .text {
      @media ${device.md} {
        font-size: 20px;
      }
      align-self: center;
      font-size: 16px;
      font-weight: bold;
    }
    cursor: pointer;
  }
  .collapse-button {
    @media ${device.md} {
      width: 80px;
      height: 80px;
      img {
        width: 30px;
      }
    }
    width: 50px;
    height: 50px;
    img {
      width: 20px;
    }
  }
`;
const Background = styled.div`
  background-color: white;
  padding-top: 200px;
  padding-bottom: 200px;
  margin-top: -172px;
`;

const texts = [
  {
    title: '日本語で依頼できますか？',
    description:
      '安心して日本語でご連絡ください。日本人マネージャーがしっかりとご相談にのりますので、細かいご要望にも対応できます。また日本語が堪能なコミュニケーターもプロジェクトに参加いたしますので、コミュニケーションで困る事はありません。',
  },
  {
    title: '依頼をしてから見積もりをもらうまではどれくらいかかりますか？',
    description:
      '通常のお見積りは１週間、デザインのご提案を合わせて行う際は１～２週間いただいております。また、お見積りにあたり情報が不足している場合は、Q&A等のやりとりをさせていただくため、お時間が必要となる場合もございます。',
  },
  {
    title: '開発に時間がかかりそうで心配なのですが…。',
    description:
      '日本国内で開発を依頼する場合と特に変わりはございません。必要な手続きやスケジュールは、弊社からご案内いたします。',
  },
  {
    title: '海外に依頼するということで品質が心配です。',
    description:
      '品質に最も影響が大きいところは、ご要件を正しく理解できているかどうかという部分になります。経験豊富な日本人、BSE、コミュニケーターがご要件を整理し、チームに正確に伝達いたします。もちろん、社内に品質管理体制、仕組みが整備されていますのでご心配は不要です。',
  },
  {
    title: '日本でも使用している仕様書や資料でも作業できますか？',
    description:
      'はい、日本で使用している仕様書、資料をそのままご提供いただくケースが殆どです。特別な資料をご用意いただく必要はございません。',
  },
  {
    title: '日本国内での外注に比べるとコストはどれくらい下がりますか？',
    description:
      'ご要件にもよりますが、通常５０％から７０％のコストダウンが見込めます。特に、案件規模が大きければ大きいほど更に高いコストダウン率につながります。',
  },
  {
    title: '請負型とラボ型、どちらの方がいいでしょうか？',
    description:
      'ラボ型では、チーム内にノウハウを蓄積し、プロセスやワークフローを改善しながら開発を行うことでプロジェクトを確実に成功へと導くことができます。プロジェクト型の開発では、そのプロジェクトが終わると、次の開発に固有の経験を生かせませんので、長期的に見ればラボ型が良いと言えます。',
  },
  {
    title: '担当者をベトナム駐在にしなくてはいけませんか？',
    description:
      '駐在していただく必要はございません。ラボのセットアップをしっかりと行い、仕組みを構築しますので遠隔コミュニケーションのみでも運営できます。実際に１年以上遠隔コミュニケーションのみで運営するようなケースもございます。',
  },
  {
    title: '残業や休日出勤は対応してもらえますか？',
    description:
      '目的や理由を明確に説明し、双方が納得したうえで残業や休日出勤に対応しています。そのため不平や不満も出ませんし、やりがいをもって対応することができていますので、パフォーマンスに影響が出ることもありません。',
  },
  {
    title: '何人のチームからスタートできますか？',
    description:
      'ラボのパフォーマンスを十分発揮し、安定した運営を可能にするため、基本的に５名（リーダー１名、メンバー４名）以上推奨となります。ご要件によってはそれ以下の人数でスタートする場合もございます。',
  },
  {
    title: '撤退時はどのようになりますか？',
    description:
      '契約期間満了の１．５ヵ月前までにラボの評価および継続判断をしていただきます。',
  },
  {
    title: 'WEBシステムやアプリのUIデザインなども依頼できますか？',
    description:
      'ホームページの制作から、あらゆる業界の業務システム開発、WEBサービスの開発等も含みます。',
  },
  {
    title: 'アプリ開発は企画段階から相談はできますか？',
    description:
      'はい、おおまかなコンセプトをお持ちであれば、具体的な企画への落とし込みまでお手伝いすることができます。実現方法やサービスモデルなどをご提案するケースもございます。',
  },
  {
    title: 'アプリ開発後の保守運用や配布なども心配です…',
    description:
      '保守運用体制を構築し、アプリの審査申請や限定的な配布、バージョンアップといった作業を弊社にて行うことができます。',
  },
];

const CollapseBoard: FC<{ title: string; description: string }> = ({
  title,
  description,
}) => {
  const [isOpen, setOpen] = useState(false);
  const tooggle = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);
  return (
    <CollapseBoardStyle>
      <div>
        <Button className='collapse-button' color='link' onClick={tooggle}>
          {isOpen ? <img src={icon2} /> : <img src={icon1} />}
        </Button>
      </div>
      <div className='b-content'>
        <div onClick={tooggle} className='title'>
          <div className='text'>{title}</div>
        </div>
        <Collapse isOpen={isOpen}>
          <div className='title-desciption'>{description}</div>
        </Collapse>
      </div>
    </CollapseBoardStyle>
  );
};

const Section8 = () => (
  <>
    <Background>
      <Container className='container-xxl'>
        <Section id='faq'></Section>
        <MT />
        <MobilePadding>
          <Row className='justify-content-center'>
            <Col xs={12} md={6}>
              <Text1 id='Frequently_Asked_Questions'>
                <span>Frequently Asked Questions</span>
              </Text1>
              <Text2 id='Text_ep'>
                <span>よくある質問</span>
              </Text2>
            </Col>
          </Row>
          <MT />
          <Row className='justify-content-center'>
            <Col xs={12} md={10}>
              {texts.map((text) => (
                <CollapseBoard
                  title={text.title}
                  description={text.description}
                />
              ))}
            </Col>
          </Row>
        </MobilePadding>
      </Container>
    </Background>
  </>
);

export default Section8;
