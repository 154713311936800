import OutlineButton from 'components/button/OutlineButton';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { MobilePadding } from 'utilities/mobile-pad';
import Section from 'utilities/section';

import icon1 from './images/icon1.svg';
import icon2 from './images/icon2.svg';
import icon3 from './images/icon3.svg';
import icon4 from './images/icon4.svg';
import icon5 from './images/icon5.svg';

import num01 from './images/01.png';
import num02 from './images/02.png';
import num03 from './images/03.png';
import num04 from './images/04.png';
import num05 from './images/05.png';

import { Container } from 'utilities/container';
import fixture from './images/fixture.svg';
const Text1 = styled.div`
  color: #838383;
  text-align: center;
`;
const Text2 = styled.div`
  text-align: center;
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 20px;
`;
const MT = styled.div`
  margin-top: 48px;
`;
const ImageWrap = styled.div`
  position: relative;
  .arrow {
    position: absolute;
    right: -43px;
    top: 76px;
  }
`;
const ImageNumber = styled.img`
  width: 33px;
`;
const IconWrap = styled.div`
  background-color: white;
  width: 170px;
  height: 170px;
  display: grid;
  border-radius: 170px;
  position: relative;
  margin: auto;
  margin-bottom: 12px;
  z-index: 10;
  .icon-svg {
    justify-self: center;
    align-self: center;
  }
  .number {
    position: absolute;
    top: 0px;
    font-size: 48px;
    color: #1890ff;
  }
`;

const ItemTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;
`;
const ItemText = styled.div`
  font-size: 14px;
  margin-bottom: 12px;
`;

const Section6 = () => (
  <>
    <Container className='container-xxl'>
      <MobilePadding>
        <Section id='flow'></Section>
        <MT>
          <Row className='justify-content-center'>
            <Col sm={12} md={4}>
              <Text1 id='steps_to_adopt_lab_development'>
                <span>STEPS TO ADOPT LAB DEVELOPMENT</span>
              </Text1>
              <Text2 id='Text_bo'>
                <span>ラボ開発の流れ</span>
              </Text2>
            </Col>
          </Row>
        </MT>
        <MT>
          <Row className=' justify-content-lg-center'>
            <Col xs={6} md={4} lg={2}>
              <ImageWrap>
                <IconWrap>
                  <object className='icon-svg' data={icon1} />
                  <ImageNumber className='number' src={num01} />
                </IconWrap>
                <object className='arrow' data={fixture} />
              </ImageWrap>

              <ItemTitle id='Text_cq'>
                <span>徹底ヒアリング</span>
              </ItemTitle>
              <ItemText id='n__cv'>
                <span>まずはご要望を詳しくお 聞かせください。</span>
              </ItemText>
            </Col>
            <Col xs={6} md={4} lg={2}>
              <ImageWrap>
                <IconWrap>
                  <object className='icon-svg' data={icon2} />
                  <ImageNumber className='number' src={num02} />
                </IconWrap>
                <object className='arrow d-none d-md-block' data={fixture} />
              </ImageWrap>
              <ItemTitle id='Text_cr'>
                <span>提案・お見積もり</span>
              </ItemTitle>
              <ItemText id='n___cw'>
                <span>
                  ご要望に見合った開発 チームを提案し、審査頂 きます。
                </span>
              </ItemText>
            </Col>
            <Col xs={6} md={4} lg={2}>
              <ImageWrap>
                <IconWrap>
                  <object className='icon-svg' data={icon3} />
                  <ImageNumber className='number' src={num03} />
                </IconWrap>
                <object className='arrow d-md-none d-lg-block' data={fixture} />
              </ImageWrap>
              <ItemTitle id='Text_cs'>
                <span>契約・ご発注</span>
              </ItemTitle>
              <ItemText id='n___cx'>
                <span>
                  お客様と合意した内容を 基に契約書を交わさせて いただきます。
                </span>
              </ItemText>
            </Col>
            <Col xs={6} lg={2}>
              <ImageWrap>
                <IconWrap>
                  <object className='icon-svg' data={icon4} />
                  <ImageNumber className='number' src={num04} />
                </IconWrap>
                <object className='arrow d-none d-md-block' data={fixture} />
              </ImageWrap>
              <ItemTitle id='Text_ct'>
                <span>ラボ型開発業務</span>
              </ItemTitle>
              <ItemText id='n__cy'>
                <span>お客様とやり取りしなが ら開発を進めていきます。</span>
              </ItemText>
            </Col>
            <Col xs={6} lg={2}>
              <ImageWrap>
                <IconWrap>
                  <object className='icon-svg' data={icon5} />
                  <ImageNumber className='number' src={num05} />
                </IconWrap>
              </ImageWrap>
              <ItemTitle id='Text_cu'>
                <span>成果物レビュー</span>
              </ItemTitle>
              <ItemText id='n____cz'>
                <span>
                  ゴールを達成するまで、 納品・お客様とのレ
                  ビュー・開発を繰り返し ていきます。
                </span>
              </ItemText>
            </Col>
          </Row>
        </MT>
        <MT>
          <Row className='justify-content-center'>
            <Col className=' d-flex justify-content-center'>
              <OutlineButton href='#inquiry' className='btn btn-link'>
                <div id='Text_me'>
                  <span>お問い合わせ</span>
                </div>
              </OutlineButton>
            </Col>
          </Row>
        </MT>
      </MobilePadding>
    </Container>
  </>
);

export default Section6;
