import { Helmet } from 'react-helmet';
import Header from 'layout/Header/Header';
import styled from 'styled-components';
import Section1 from './Component/Section1/Section1';
import Section2 from './Component/Section2/Section2';
import Section3 from './Component/Section3/Section3';
import Section4 from './Component/Section4/Section4';
import Section5 from './Component/Section5/Section5';
import Section6 from './Component/Section6/Section6';
import Section8 from './Component/Section8/Section8';
import Section7 from './Component/Section7/Sectioin7';
import Section9 from './Component/Section9/Section9';
import Section10 from './Component/Section10/Section10';
import BackToTopButton from 'components/button/BackToTopButton';
import Section2OurPartner from './Component/Section2OurPartner/Section2OurPartner';

const Main = styled.main`
  overflow: hidden;
  background-color: #e6f7ff;
`;

const Home = () => {
  const title = 'freeC Asia | Công Nghệ Tuyển Dụng, Tìm Việc Làm Nhanh';
  const description =
    'Website tuyển dụng, tìm việc nhanh, uy tín, chất lượng. Tìm việc các ngành nghề, địa điểm và các công ty đang tuyển dụng. Dễ dàng tạo CV online và ứng tuyển nhanh chóng.';
  const keywords =
    'freec, freec asia, tuyển dụng, tuyển dụng nhanh, website tuyển dụng, tìm việc nhanh, tìm việc uy tín, tạo CV online, công ty đang tuyển dụng';
  const image = '/img/ogp.png';
  return (
    <>
      <Helmet>
        <title>freeC - Website tuyển dụng thông minh</title>
        <link
          rel='shortcut icon'
          type='image/png'
          sizes='32x32'
          href='/favicon_32x32.png'></link>
        <link
          rel='shortcut icon'
          type='image/png'
          sizes='16x16'
          href='/favicon_16x16.png'></link>
        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link rel='preconnect' href='https://fonts.gstatic.com' />
        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link rel='preconnect' href='https://fonts.gstatic.com' crossOrigin />
        <link
          href='https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap'
          rel='stylesheet'
        />
        <link
          rel='stylesheet'
          href='https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css'></link>
        <style>
          {`
          body {
            font-family: 'Noto Sans JP';
            color: #262626;
          }
          `}
        </style>
        <title>{title}</title>
        <meta name='viewport' content='initial-scale=1.0, width=device-width' />
        <meta name='description' content={description} />
        {keywords && <meta name='keywords' content={keywords} />}

        {keywords && <meta itemProp='keywords' content={keywords} />}
        <meta itemProp='name' content={title} />
        <meta itemProp='description' content={description} />
        <meta itemProp='image' content={image} />

        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
        <meta property='og:image' content={image} />

        <meta property='robots' content='noodp,index,follow' />
        <meta property='og:type' content='website' />

        <meta name='twitter:card' content={title} />
        <meta name='twitter:site' content='freec.asia' />
        <meta name='twitter:image' content={image} />
        <meta name='twitter:title' content={title} />
        <meta name='twitter:description' content={description} />

        <meta
          name='google-site-verification'
          content='WrBOtNU-Tm7D1bjop31Bd8AK9Z_svs7YGahGk0KmfvU'
        />
        <script
          async
          src='https://www.googletagmanager.com/gtag/js?id=G-9XK1JM3SRB'></script>
      </Helmet>
      <Helmet
        script={[
          {
            type: 'text/javascript',
            innerHTML: ` window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);}   gtag('js', new Date());   gtag('config', 'G-9XK1JM3SRB');`,
          },
        ]}
      />
      <Helmet
        script={[
          {
            type: 'text/javascript',
            innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-W27CMS5');`,
          },
        ]}
      />

      <noscript>
        <iframe
          src='https://www.googletagmanager.com/ns.html?id=GTM-W27CMS5'
          height='0'
          width='0'
          style={{ display: 'none', visibility: 'hidden' }}></iframe>
      </noscript>
      <script
        defer
        data-domain='lab.freec.asia'
        src='https://tracking.freec.asia/js/plausible.js'></script>
      <Header />
      <Main>
        <Section1 />
        <Section2 />
        <Section2OurPartner />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
        <Section8 />
        <Section9 />
        <Section10 />
        <BackToTopButton />
      </Main>
    </>
  );
};
export default Home;
