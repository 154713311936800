import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
} from '@bootstrap-styled/v4';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useState } from 'react';
import styled from 'styled-components';
import { LOCALE } from 'type/language';
import iconvi from './images/icon-vi.png';
import iconen from './images/icon-en.png';
import iconjp from './images/icon-jp.png';

const LanguageButton = styled(Button)`
  background: white;
  &:hover {
  }
`;
const LangImg = styled.img`
  width: 20px;
  margin-right: 12px;
`;
const SwitchLanguage = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const { language, changeLanguage } = useI18next();
  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
      <LanguageButton disabled onClick={() => toggle()} color='white'>
        {
          {
            [LOCALE.jp]: (
              <span>
                <LangImg src={iconjp} /> Jp
              </span>
            ),
            [LOCALE.en]: (
              <span>
                <LangImg src={iconen} /> En
              </span>
            ),
            [LOCALE.vi]: (
              <span>
                <LangImg src={iconvi} /> Vi
              </span>
            ),
          }[language]
        }
      </LanguageButton>
      <DropdownMenu>
        <DropdownItem
          onClick={() => {
            changeLanguage(LOCALE.jp);
          }}>
          <LangImg src={iconjp} />
          Jp
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            changeLanguage(LOCALE.en);
          }}>
          <LangImg src={iconen} />
          En
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            changeLanguage(LOCALE.vi);
          }}>
          <LangImg src={iconvi} />
          Vi
        </DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
};
export default SwitchLanguage;
