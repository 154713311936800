import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import Section from 'utilities/section';
const Text1 = styled.div`
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 12px;
`;
const Text2 = styled.div`
  text-align: center;
  font-size: 14px;
  color: white;
  margin-bottom: 40px;
`;
const Text3 = styled.div`
  text-align: center;
  font-size: 14px;
  color: #838383;
  margin-bottom: 20px;
`;

const BackGround = styled.div`
  background-color: #262626;
  padding-top: 30px;
  z-index: 4;
  position: relative;
`;
const Section10 = () => (
  <BackGround>
    <Row className='justify-content-center text-center'>
      <Col sm={12} md={6}>
        <Text1 id='FREECRACY_VIETNAM_COMPANY_LIMI'>
          <span>FREECRACY VIETNAM COMPANY LIMITED</span>
        </Text1>
        <Text2 id='Group_4096'>
          <div id='Floor_06_BCONS_TOWER_II_421_Un'>
            <span>
              Floor 06, BCONS TOWER II
              <br />
              42/1, Ung Van Khiem Street, Ward 25, Binh Thanh District,
              Hochiminh City, Vietnam
              <br />
              Tel: (84.28) 2253 4031
            </span>
          </div>
        </Text2>
        <Text3 id='Copyright__2021_FREECRACY_VIET'>
          <span>Copyright © 2021 FREECRACY VIETNAM COMPANY LIMITED.</span>
        </Text3>
      </Col>
    </Row>
  </BackGround>
);

export default Section10;
