import { useField } from 'formik';

const FormikBoostrapTextAreaField = (props: {
  name: string;
  type?: string;
  className: string;
  label: string;
}) => {
  const { name, type } = props;
  const [field, meta] = useField({ name, type });
  const errorText = meta.error && meta.touched ? meta.error : '';
  return (
    <div className={`form-floating ${props.className}`}>
      <textarea
        {...field}
        className={`form-control ${errorText ? ' is-invalid' : ''}`}
        placeholder={props.label}
      />
      <label>{props.label}</label>
      {meta.error && <div className='invalid-feedback'>{errorText}</div>}
    </div>
  );
};
export default FormikBoostrapTextAreaField;
