import { FC } from 'react';
import styled from 'styled-components';
import { device } from 'utilities/constaint';

const TableContainer = styled.div`
  @media ${device.lg} {
    margin: 0;
  }
  margin: 0 12px;
  background-color: white;
  display: flex;
  align-items: center;
  border-radius: 8px;
  &.green {
    &:before {
      background-color: #1adec8;
    }
    .strong-text {
      color: #1adec8;
    }
    tbody {
      tr {
        &:nth-child(odd) {
          background: #1adec833;
        }
      }
    }
  }
  &.yellow {
    &:before {
      background-color: #f8c92e;
    }
    .strong-text {
      color: #f8c92e;
    }
    tbody {
      tr {
        &:nth-child(odd) {
          background: #f8c92e36;
        }
      }
    }
  }
  &.pink {
    &:before {
      background-color: #c95189;
    }
    .strong-text {
      color: #c95189;
    }
    tbody {
      tr {
        &:nth-child(odd) {
          background: #c9518940;
        }
      }
    }
  }

  &:before {
    content: ' ';
    display: block;
    width: 5px;
    height: 86px;
    border-radius: 0 10px 10px 0;
  }
  table {
    @media ${device.md} {
      margin: 16px;
    }
    margin: 8px;
    flex: 1;
  }
  .strong-text {
    font-size: 16px;
  }
  thead {
    tr {
      th {
        @media ${device.md} {
          padding: 0 12px;
        }

        text-align: right;
        padding-bottom: 6px;
        font-size: 12px;
      }
      th:first-child {
        text-align: left;
        padding-bottom: 8px;
      }
    }
  }
  tbody {
    td {
      font-size: 12px;
      text-align: right;
      padding-bottom: 6px;
    }
    td:first-child {
      @media ${device.md} {
        min-width: 150px;
      }
      text-align: left;
      padding-left: 10px;
      font-weight: bold;
      border-left-style: solid;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    td:last-child {
      padding-right: 10px;
      border-right-style: solid;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
`;

export const Table1: FC<{ style?: object }> = ({ style }) => (
  <TableContainer className='green' style={style}>
    <table>
      <thead>
        <tr>
          <th className='strong-text'>プロマネ・ブリッジSE等</th>
          <th>登録者数</th>
          <th>経験年数</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>サーバー管理者</td>
          <td>3.069</td>
          <td>4.2 年</td>
        </tr>
        <tr>
          <td>プロジェクトマネージャ</td>
          <td>6.527</td>
          <td>4.1 年</td>
        </tr>
        <tr>
          <td>ブリッジSE</td>
          <td>2.311</td>
          <td>3.3 年</td>
        </tr>
        <tr>
          <td>UI/UXデザイナー</td>
          <td>4,967</td>
          <td>2.1 年</td>
        </tr>
      </tbody>
    </table>
  </TableContainer>
);

export const Table2: FC<{ style?: object }> = ({ style }) => (
  <TableContainer className='yellow' style={style}>
    <table>
      <thead>
        <tr>
          <th className='strong-text'>フロントエンド</th>
          <th>登録者数</th>
          <th>経験年数</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>JavaScript</td>
          <td>13,915</td>
          <td>3.5 年</td>
        </tr>
        <tr>
          <td>React</td>
          <td>7,702</td>
          <td>3.3 年</td>
        </tr>
        <tr>
          <td>Vue</td>
          <td>4,705</td>
          <td>3.9 年</td>
        </tr>
        <tr>
          <td>Flutter</td>
          <td>3,160</td>
          <td>2.4 年</td>
        </tr>
      </tbody>
    </table>
  </TableContainer>
);

export const Table3: FC<{ style?: object }> = ({ style }) => (
  <TableContainer className='pink' style={style}>
    <table>
      <thead>
        <tr>
          <th className='strong-text'>バックエンド</th>
          <th>登録者数</th>
          <th>経験年数</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Java</td>
          <td>20,908</td>
          <td>3.6 年</td>
        </tr>
        <tr>
          <td>C</td>
          <td>21773</td>
          <td>3.4 年</td>
        </tr>
        <tr>
          <td>PHP</td>
          <td>8,946</td>
          <td>4.2 年</td>
        </tr>
        <tr>
          <td>Python</td>
          <td>7,967</td>
          <td>3.2 年</td>
        </tr>
      </tbody>
    </table>
  </TableContainer>
);
