import { Button, Modal, ModalBody } from 'reactstrap';
import styled from 'styled-components';
import logo from './images/head-logo.png';
import iconvi from './images/icon-vi.png';
import iconen from './images/icon-en.png';
import iconjp from './images/icon-jp.png';
import { FC } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { LOCALE } from 'type/language';
import iconclose from './images/icon-close.svg';

const FixModal = styled(Modal)`
  &.modal-dialog {
    max-width: 100vw;
    margin: 0;
    min-height: 100vh;

    .modal-content {
      min-height: 100vh;
    }
  }
  a,
  button {
    &.btn-link {
      font-weight: 400;
      color: #262626;
      text-decoration: none;
    }
    &.lang {
      background: #e6f7ff;
      img {
        margin-right: 20px;
      }
      &.active {
        background: white;
        border: 1px solid #1890ff;
      }
    }
  }
`;
const MenuItems = styled.div`
  display: flex;
  flex: 1;
  padding: 12px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  a {
    font-size: 24px;
    font-weight: bold;
  }
`;
const ModalMenu: FC<{ isOpen: boolean; toggle: () => unknown }> = ({
  isOpen,
  toggle,
}) => {
  const toggleDelay = () =>
    setTimeout(() => {
      toggle();
    }, 500);
  const { language, changeLanguage } = useI18next();
  return (
    <FixModal isOpen={isOpen} toggle={toggle}>
      <ModalBody className='d-flex flex-column justify-content-between'>
        <div className='d-flex justify-content-between'>
          <img src={logo} />
          <Button onClick={toggle} color='link'>
            <img src={iconclose} />
          </Button>
        </div>
        <MenuItems>
          <a
            onClick={toggleDelay}
            href='#top'
            className='btn btn-link'
            color='link'>
            トップ
          </a>
          <a onClick={toggleDelay} href='#talents' className='btn btn-link'>
            データベース
          </a>
          <a onClick={toggleDelay} href='#partner' className='btn btn-link'>
            弊社取引先実績
          </a>

          <a onClick={toggleDelay} href='#strengths' className='btn btn-link'>
            freeCの強み
          </a>
          <a
            onClick={toggleDelay}
            href='#lab-development'
            className='btn btn-link'>
            ラボ開発とは
          </a>
          <a onClick={toggleDelay} href='#flow' className='btn btn-link'>
            ラボ開発の流れ
          </a>
          <a onClick={toggleDelay} href='#expansion' className='btn btn-link'>
            拡大
          </a>
          <a onClick={toggleDelay} href='#faq' className='btn btn-link'>
            FAQ
          </a>
          <a className='btn btn-link' href='#inquiry'>
            お問い合わせ
          </a>
        </MenuItems>
        <div className='d-flex justify-content-between'>
          <Button
            onClick={() => changeLanguage(LOCALE.jp)}
            className={`lang ${language === LOCALE.jp && 'active'}`}
            color='link'>
            <img src={iconjp} /> JP
          </Button>
          <Button
            disabled
            onClick={() => changeLanguage(LOCALE.en)}
            className={`lang ${language === LOCALE.en && 'active'}`}
            color='link'>
            <img src={iconen} />
            EN
          </Button>
          <Button
            disabled
            onClick={() => changeLanguage(LOCALE.vi)}
            className={`lang ${language === LOCALE.vi && 'active'}`}
            color='link'>
            <img src={iconvi} />
            VL
          </Button>
        </div>
      </ModalBody>
    </FixModal>
  );
};
export default ModalMenu;
