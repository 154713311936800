import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import backgound from './images/backgound.png';
import backgoundMoblie from './images/backgound-mobile.png';
import { device } from 'utilities/constaint';
import { MobilePadding, mobilePadding } from 'utilities/mobile-pad';
import Section from 'utilities/section';
import triangle from './images/triangle.svg';
const Backgound = styled.div`
  @media ${device.sm} {
    background-image: url(${backgound});
    background-size: cover, contain;
  }
  background-image: url(${backgoundMoblie});
  background-size: cover, cover;
  background-position-x: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  .bottom-tri {
    width: 100%;
    position: relative;
    bottom: -7px;
  }
`;
const TextColor = styled.div`
  color: white;
  margin-top: 160px;
  text-align: center;
`;
const Text1 = styled.div`
  color: #86c5ff;
  text-transform: uppercase;
`;
const Text2 = styled.div`
  color: white;
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 20px;
`;
const Text3 = styled.div`
  color: white;
  font-size: 16px;
  max-width: 600px;
  margin: auto;
`;

const Text4 = styled.div`
  color: #838383;
  text-align: center;
  text-transform: uppercase;
`;
const Text5 = styled.div`
  text-align: center;
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 20px;
`;
const Text6 = styled.div`
  font-size: 16px;
  max-width: 970px;
  margin: auto;
  text-align: center;
`;

const WhiteBack = styled.div`
  background-color: white;
  padding-top: 196px;
  padding-bottom: 24px;
  margin-top: -172px;
`;
const Section4 = () => (
  <>
    <Section id='lab-development'></Section>
    <Backgound>
      <MobilePadding>
        <Row className='justify-content-center'>
          <Col sm={12} md={6}>
            <TextColor id='Group_3725'>
              <div id='Group_3718'>
                <Text1 id='Whats_lab_development'>
                  <span>What’s lab development?</span>
                </Text1>
                <Text2 id='Text_bx'>
                  <span>ラボ開発とは</span>
                </Text2>
              </div>
              <Text3 id='n___bv'>
                <span>
                  開発業務を依頼をする際の契約形態のひとつであり、ラボ型開発とも呼ばれます。
                  どのような契約かというと、一定期間一定数の人材を確保し、開発業務を行うことを約束するものです。
                  契約期間中であればプロジェクトの進捗状況に応じて開発内容の変更も可能です。
                </span>
              </Text3>
            </TextColor>
          </Col>
        </Row>
      </MobilePadding>

      <object className='bottom-tri' data={triangle} />
    </Backgound>
    <WhiteBack>
      <Section id='difference'></Section>
      <MobilePadding>
        <Row className='justify-content-center'>
          <Col sm={12} md={6}>
            <div id='Group_3717'>
              <Text4 id='Whats_the_difference'>
                <span>What’s the difference?</span>
              </Text4>
              <Text5 id='Text_br'>
                <span>請負契約との違い</span>
              </Text5>
            </div>
            <Text6 id='n__'>
              <span>
                ラボ契約と似たものに、請負契約というものがあります。どちらも「自社ではできない」「他社に委託した方が効率的だ」という理由で外部に業務を委託するものです。しかし、ラボ契約には請負契約と大きく異なっている点があります。
                <br />
                <br />
                ラボ契約では、一定期間専属チームとして人材を確保し共に開発をすすめます。プロジェクトが終了しても契約期間内なら、継続して人員を提供してもらえます。請負契約は、契約で決められた納期・工数で依頼したプロジェクトを完成させます。プロジェクトの制作すべてを委託し、委託側が開発に関わることは原則的にありません。
              </span>
            </Text6>
          </Col>
        </Row>
      </MobilePadding>
    </WhiteBack>
  </>
);
export default Section4;
