import styled from 'styled-components';
import { device } from 'utilities/constaint';
import svg1 from './images/svg1.svg';
import eclip from './images/eclip.svg';
import { Col, Row } from 'reactstrap';
import OutlineButton from 'components/button/OutlineButton';
import { MT } from 'utilities/margin';
import { Container } from 'utilities/container';
import { MobilePadding } from 'utilities/mobile-pad';

const HomeSVG = styled.object`
  width: 100%;
`;

const Backgound1 = styled.div`
  @media ${device.md} {
    padding-top: 78px;
  }
  padding-top: 53px;
  background-color: white;
  .bottom-tri {
    width: 100%;
    position: relative;
    z-index: 1;
    bottom: -6px;
  }
  .eclise {
    @media ${device.md} {
      width: 815px;
      top: 72px;
      left: -299px;
    }
    width: 518px;
    position: absolute;
    top: 241px;
    left: -169px;
  }
`;
const Triangle = styled.div`
  border-color: yellow blue #1890ff transparent;
  border-style: solid;
  border-width: 0px 0px 13vw 100vw;
  height: 0px;
  width: 0px;
  margin-top: -7vw;
  position: relative;
  z-index: 3;
`;
const Group1 = styled.div`
  position: relative;
  @media ${device.sm} {
    font-size: 40px;
  }
  @media ${device.xl} {
    font-size: 48px;
  }
  @media ${device.xxl} {
    font-size: 50px;
  }
  font-size: 32px;
  font-family: 'Noto Sans JP';
  font-weight: 900;
  line-height: 80px;
`;

const Section1 = () => (
  <section>
    <Backgound1 id='top'>
      <object className='eclise' data={eclip} />
      <Container
        className='container-xxl'
        style={{ position: 'relative', zIndex: 6, marginBottom: -90 }}>
        <MT />
        <MobilePadding>
          <Row className='justify-content-center align-items-center'>
            <Col xs={12} sm={10} md={10} lg={5}>
              <Group1>
                <span>
                  圧倒的なエンジニア
                  <br />
                  データベースを持つ
                </span>
                <br />
                <span style={{ color: 'rgba(24,144,255,1)' }}>freeC</span>
                <span>
                  だからこそできる
                  <br />
                  最高のラボ開発を提供
                </span>
              </Group1>
              <MT />
              <OutlineButton
                href='#inquiry'
                className='btn btn-link'
                style={{ width: 200, minWidth: 180, fontSize: 16 }}>
                お問い合わせ
              </OutlineButton>
            </Col>
            <Col xs={12} sm={10} md={8} lg={5}>
              <MT />
              <HomeSVG data={svg1} />
            </Col>
          </Row>
        </MobilePadding>
      </Container>

      <Triangle />
    </Backgound1>
  </section>
);

export default Section1;
