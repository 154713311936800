import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
const ToastContainer = styled.div`
  z-index: 100;
`;
const ToastAction = ({
  destroy,
  title,
  message,
}: {
  destroy: () => void;
  title: string;
  message: string;
}) => {
  const [show, setShow] = useState(0);
  useEffect(() => {
    setShow(1);
    setTimeout(() => {
      setShow(2);
      destroy();
    }, 2000);
  }, []);
  return (
    <ToastContainer className='position-fixed bottom-0 end-0 p-3'>
      <div
        id='liveToast'
        className={`toast fade ${{ 0: 'showing', 1: 'show', 2: 'hide' }[show]}`}
        role='alert'
        aria-live='assertive'
        aria-atomic='true'>
        <div className='toast-header'>
          <strong className='me-auto'>{title}</strong>
          {/* <small>11 mins ago</small> */}
          <button
            onClick={() => setShow(2)}
            type='button'
            className='btn-close'
            data-bs-dismiss='toast'
            aria-label='Close'></button>
        </div>
        <div className='toast-body'>{message}</div>
      </div>
    </ToastContainer>
  );
};

export function toastSuccess({
  title,
  message,
}: {
  title: string;
  message: string;
}): void {
  const div = document.createElement('div');
  document.body.appendChild(div);
  ReactDOM.render(
    <ToastAction
      title={title}
      message={message}
      destroy={() => {
        setTimeout(() => ReactDOM.unmountComponentAtNode(div), 2000);
      }}
    />,
    div,
  );
}
export default ToastAction;
