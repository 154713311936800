import { useState } from 'react';
import Slider from 'react-slick';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { device } from 'utilities/constaint';
import svg2 from './images/svg2.svg';
import { Table1, Table2, Table3 } from './Tables';
const SVG2 = styled.object`
  height: 350px;
`;
const CustomSlider = styled(Slider)`
  margin-top: 0px;
  margin-bottom: 50px;
  @media ${device.md} {
    margin-top: 40px;
  }
  .slick-dots {
    bottom: -35px;
    li {
      button:before {
        font-family: 'slick';
        font-size: 6px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.5;
        color: white;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      &:nth-child(2) {
        button:before {
          color: red;
          width: 30px;
          height: 10px;
          border-radius: 10px;
          content: ' ';
          top: 5px;
          left: -5px;
          opacity: 1;
          background-color: white;
        }
      }
    }
  }
  .slick-slide {
    &.slick-active {
      opacity: 1;
    }
    opacity: 0.5;
  }
`;
export const SliderChart = () => {
  const [showOption, setShowOption] = useState(0);
  return (
    <>
      <Row>
        <Col xs={12} className='d-flex flex-column  align-items-center'>
          <div>
            <SVG2 data={svg2} />
          </div>
          <svg
            style={{ marginTop: -283 }}
            xmlns='http://www.w3.org/2000/svg'
            width='343'
            height='320'
            viewBox='0 0 343 320'>
            <g
              opacity={showOption === 1 ? 1 : 0}
              fill='#ffeaa5'
              stroke='#ffeaa5'>
              <path
                id='Path_2616'
                data-name='Path 2616'
                d='M250,10 L250 300 L171 300 L171 320'
                fill='none'
                stroke-width='1'
              />
              <circle
                id='Ellipse_11'
                data-name='Ellipse 11'
                cx='8'
                cy='8'
                r='8'
                transform='translate(242 0)'
              />
              <circle
                id='Ellipse_12'
                data-name='Ellipse 12'
                cx='4'
                cy='4'
                r='4'
                transform='translate(246 4)'
                fill='#af8e22'
              />
            </g>
            <g
              opacity={showOption === 0 ? 1 : 0}
              fill='#91f3e7'
              stroke='#91f3e7'>
              <path
                id='Path_2616'
                data-name='Path 2616'
                d='M90,70 L90 300 171 300 L171 320'
                fill='none'
                stroke-width='1'
              />
              <circle
                id='Ellipse_11'
                data-name='Ellipse 11'
                cx='8'
                cy='8'
                r='8'
                transform='translate(82 68)'
              />
              <circle
                id='Ellipse_12'
                data-name='Ellipse 12'
                cx='4'
                cy='4'
                r='4'
                transform='translate(86 72)'
                fill='#af8e22'
              />
            </g>
            <g
              opacity={showOption === 2 ? 1 : 0}
              fill='#f2b6d4'
              stroke='#f2b6d4'>
              <path
                id='Path_2616'
                data-name='Path 2616'
                d='M190,210 L190 300 171 300 L171 320'
                fill='none'
                stroke-width='1'
              />
              <circle
                id='Ellipse_11'
                data-name='Ellipse 11'
                cx='8'
                cy='8'
                r='8'
                transform='translate(182 208)'
              />
              <circle
                id='Ellipse_12'
                data-name='Ellipse 12'
                cx='4'
                cy='4'
                r='4'
                transform='translate(186 212)'
                fill='#af8e22'
              />
            </g>
          </svg>
        </Col>
      </Row>

      <div>
        <CustomSlider
          afterChange={(current) => setShowOption(current)}
          {...{
            className: 'center',
            dots: true,
            centerMode: true,
            infinite: true,
            centerPadding: '30px',
            slidesToShow: 1,
            speed: 500,
          }}>
          <div>
            <Table1 />
          </div>
          <div>
            <Table2 />
          </div>
          <div>
            <Table3 />
          </div>
        </CustomSlider>
      </div>
    </>
  );
};
