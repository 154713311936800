import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import img1 from './images/img1.jpg';
import img2 from './images/img2.jpg';
import img3 from './images/img3.jpg';

import avatar1 from './images/avatar1.png';
import avatar2 from './images/avatar2.png';
import avatar3 from './images/avatar3.png';
import eclip from './images/eclip.svg';
import { device } from 'utilities/constaint';
import Section from 'utilities/section';
import toptri from './images/top-tri.svg';
import bottomtri from './images/bottom-tri.svg';
import { Container } from 'utilities/container';
import { MobilePadding } from 'utilities/mobile-pad';

import number1 from './images/01.png';
import number2 from './images/02.png';
import number3 from './images/03.png';
import fixture1 from './images/fixture-1.svg';

const Text1 = styled.div`
  color: #86c5ff;
  text-align: center;
  text-transform: uppercase;
`;
const Text2 = styled.div`
  color: white;
  text-align: center;
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 20px;
`;
const Text3 = styled.div`
  color: white;
  font-size: 16px;
  max-width: 970px;
  margin: auto;
  text-align: center;
`;
const MT = styled.div`
  margin-top: 48px;
`;

const ImageWrap = styled.div`
  max-width: 100%;
  align-self: center;
  position: relative;
  background: white;
  border-radius: 6px;
  padding: 2px;
  img.s-image {
    background: white;
    max-width: 100%;
    min-width: 100%;
    border-radius: 6px;
  }
  .r-text {
    background: white;
    position: absolute;
    bottom: 0;
    padding: 10px;
    border-radius: 0 8px 0 8px;
    font-weight: bold;
    font-size: 18px;
  }
  .number-img {
    position: absolute;
    top: -48px;
    left: 28px;
  }
  &.i_m_b {
    margin-bottom: 32px;
  }
  &#image-3 {
    position: relative;
  }
  .fixture-1 {
    position: absolute;
    top: 31px;
    right: -24px;
  }
`;
const Board1 = styled.div`
  background-color: white;
  border-radius: 16px;
`;
const Board2 = styled.div`
  @media ${device.md} {
    padding: 22px 12px 22px 10px;
  }
  padding: 22px;
  .title-text {
    font-size: 22px;
    font-weight: bold;
    .hight-light {
      color: #1890ff;
    }
  }
  .title-text2 {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 12px;
    font-weight: bold;
  }
`;
const AvatarHolder = styled.div`
  img {
    max-width: 100%;
    min-width: 100%;
  }
  .name {
    position: relative;
    background-color: #1890ff;
    text-align: center;
    margin-top: -12px;
    z-index: 2;
    font-size: 12px;
    font-weight: bold;
    color: white;
    padding: 8px 0;
    border-radius: 0 0 8px 8px;
  }
`;
const Divider = styled.div`
  border: 1px dashed #c1c1c1;
  margin: 12px 0px;
`;
const Backgound = styled.div`
  position: relative;
  z-index: 2;
  .top-tri {
    width: 100%;
  }
  background-color: #1890ff;
  .bottom-tri {
    position: relative;
    bottom: -5px;
    width: 100%;
  }
  .eclip-1 {
    position: absolute;
    bottom: -150px;
    left: -154px;
  }
  .eclip-2 {
    position: absolute;
    bottom: 150px;
    right: -400px;
    width: 815px;
  }
`;
const AvarGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 12px;
`;
const Triangle = styled.div`
  border-color: yellow blue #ffffff transparent;
  border-style: solid;
  border-width: 0px 0px 13vw 100vw;
  height: 0px;
  width: 0px;
`;
const Section7 = () => (
  <>
    <Backgound>
      <object
        style={{ position: 'relative', top: -1 }}
        className='top-tri'
        data={toptri}
      />
      <div className='background'>
        <Container className='container-xxl' style={{ paddingBottom: 100 }}>
          <MobilePadding>
            <MT>
              <Row className='justify-content-center'>
                <Col sm={12} md={6}>
                  <Section id='expansion'></Section>
                  <Text1 id='After_lab_development'>
                    <span>After lab development</span>
                  </Text1>
                  <Text2 id='Text_dm'>
                    <span>ラボ開発のその後</span>
                  </Text2>
                  <Text3 id='Text_dk'>
                    <span>
                      ラボ開発は、柔軟に解散、維持、拡大を選ぶことができます
                    </span>
                  </Text3>
                </Col>
              </Row>
            </MT>
            <MT>
              <Row className='justify-content-center'>
                <Col sm={12} md={5} lg={5}>
                  <ImageWrap className='i_m_b'>
                    <div className='number-img'>
                      <img style={{ width: 48 }} src={number1} />
                    </div>
                    <img className='s-image' src={img1} />
                    <div className='r-text'>
                      <span>契約終了により、解散</span>
                    </div>
                  </ImageWrap>
                </Col>
                <Col sm={12} md={5} lg={5}>
                  <ImageWrap className='i_m_b'>
                    <div className='number-img'>
                      <img style={{ width: 48 }} src={number2} />
                    </div>
                    <img className='s-image' src={img2} />
                    <div className='r-text'>
                      <span>ラボ開発を継続</span>
                    </div>
                  </ImageWrap>
                </Col>
              </Row>
            </MT>
            <Row className='justify-content-center'>
              <Col md={10} lg={10}>
                <Board1>
                  <Row>
                    <Col sm={12} md={6}>
                      <ImageWrap id='image-3'>
                        <div className='number-img'>
                          <img style={{ width: 48 }} src={number3} />
                        </div>
                        <img className='s-image' src={img3} />
                        <div className='r-text'>
                          <span>
                            自社で会社を立ち上げ、 そのままエンジニアを雇用
                          </span>
                        </div>
                        <object className='fixture-1' data={fixture1} />
                      </ImageWrap>
                    </Col>
                    <Col sm={12} md={6}>
                      <Board2>
                        <div className='title-text'>
                          <span className='hight-light'>freeC</span>
                          <span>
                            が全て立ち上げ時の法務、会計、税務、採用全てお任せください！
                          </span>
                        </div>
                        <Divider />
                        <div className='title-text2'>
                          <span>
                            経験豊かなメンバーがサポートさせて頂きます。
                          </span>
                        </div>
                        <AvarGrid>
                          <div className='g-item'>
                            <AvatarHolder>
                              <div className='img-container'>
                                <img className='s-image' src={avatar1} />
                              </div>

                              <div className='name'>
                                <span>法務 Cast Global Group</span>
                              </div>
                            </AvatarHolder>
                          </div>
                          <div className='g-item'>
                            <AvatarHolder>
                              <div className='img-container'>
                                <img className='s-image' src={avatar2} />
                              </div>
                              <div className='name'>
                                <span>会計税務 I-GLOCAL</span>
                              </div>
                            </AvatarHolder>
                          </div>
                          <div className='g-item'>
                            <AvatarHolder>
                              <div className='img-container'>
                                <img className='s-image' src={avatar3} />
                              </div>

                              <div className='name'>
                                <span>採用 freeC</span>
                              </div>
                            </AvatarHolder>
                          </div>
                        </AvarGrid>
                      </Board2>
                    </Col>
                  </Row>
                </Board1>
              </Col>
            </Row>
          </MobilePadding>
        </Container>
      </div>
      <Triangle />
      <object className='eclip-1' data={eclip} />
      <object className='eclip-2' data={eclip} />
    </Backgound>
  </>
);
export default Section7;
