import { Container } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import logo1 from './images/logo_1.png';
import logo2 from './images/logo_2.png';
import logo3 from './images/logo_3.png';
import logo4 from './images/logo_4.png';
import logo5 from './images/logo_5.png';
import logo6 from './images/logo_6.png';
import logo7 from './images/logo_7.png';
import logo8 from './images/logo_8.png';
import logo9 from './images/logo_9.png';
import logo10 from './images/logo_10.png';
import logo11 from './images/logo_11.png';
import logo12 from './images/logo_12.png';
import logo13 from './images/logo_13.png';
import Text from 'components/Text/Text';
import toptri from './images/top-tri.svg';
import Section from 'utilities/section';
import { device } from 'utilities/constaint';

const LogoImage = styled.img<{ size?: { sm: number; md: number } }>`
  width: ${(props) => `${props.size?.sm}px`};
  @media ${device.md} {
    width: ${(props) => `${props.size?.md}px`};
  }
`;
const BackgroundContainer = styled.div`
  background-color: white;
  z-index: 2;
  .mt-40px {
    margin-top: 40px;
  }
  .mr-2 {
    margin-right: 8px;
  }
  .mr-3 {
    margin-right: 32px;
  }
  .text-right {
    text-align: right;
  }
  .mr-23px {
    margin-right: 23px;
  }
  .mr-md-32px {
    @media ${device.lg} {
      margin-right: 32px;
    }
  }
  .mt-24px {
    margin-top: 24px;
  }
  .mt-md-40px {
    @media ${device.lg} {
      margin-top: 40px;
    }
  }
`;
const Triangle = styled.div`
  border-color: yellow blue #ffffff transparent;
  border-style: solid;
  border-width: 0px 0px 13vw 100vw;
  height: 0px;
  width: 0px;
  margin-top: -13vw;
`;
export const Section2OurPartner = () => {
  return (
    <div style={{ position: 'relative', zIndex: 10 }}>
      <Triangle />

      <BackgroundContainer>
        <Section id='partner'></Section>
        <Container style={{ paddingBottom: 32 }}>
          <div className='text-center'>
            <Text style={{ textTransform: 'uppercase' }} color='#838383'>
              Our partners
            </Text>
            <Text size='40px' weight='bold'>
              弊社取引先実績
            </Text>
          </div>
          <div style={{ maxWidth: 900, margin: 'auto' }}>
            <div
              style={{
                paddingBottom: 40,
                flexWrap: 'wrap',
              }}
              className='d-flex mt-4 justify-content-center align-item-self-end'>
              <div>
                <LogoImage
                  size={{ sm: 103, md: 92 }}
                  className='mr-23px mr-md-32px mt-24px mt-md-40px'
                  src={logo1}
                />
              </div>
              <div>
                <LogoImage
                  size={{ sm: 205, md: 182 }}
                  className='mr-23px mr-md-32px mt-24px mt-md-40px'
                  style={{ width: 182 }}
                  src={logo2}
                />
              </div>
              <div>
                <LogoImage
                  size={{ sm: 110, md: 97 }}
                  className='mr-23px mr-md-32px mt-24px mt-md-40px'
                  src={logo3}
                />
              </div>
              <div>
                <LogoImage
                  size={{ sm: 197, md: 175 }}
                  className='mr-23px mr-md-32px mt-24px mt-md-40px'
                  src={logo4}
                />
              </div>
              <div>
                <LogoImage
                  size={{ sm: 214, md: 190 }}
                  className='mr-23px mr-md-32px mt-24px mt-md-40px'
                  src={logo5}
                />
              </div>
              <div>
                <LogoImage
                  size={{ sm: 44, md: 34 }}
                  className='mr-23px mr-md-32px mt-24px mt-md-40px'
                  style={{ width: 34 }}
                  src={logo6}
                />
              </div>
              <div>
                <LogoImage
                  size={{ sm: 150, md: 132 }}
                  className='mr-23px mr-md-32px mt-24px mt-md-40px'
                  style={{ width: 132 }}
                  src={logo7}
                />
              </div>
              <div>
                <LogoImage
                  size={{ sm: 244, md: 211 }}
                  className='mr-23px mr-md-32px mt-24px mt-md-40px'
                  style={{ width: 211 }}
                  src={logo8}
                />
              </div>
              <div>
                <LogoImage
                  size={{ sm: 130, md: 112 }}
                  className='mr-23px mr-md-32px mt-24px mt-md-40px'
                  src={logo9}
                />
              </div>
              <div>
                <LogoImage
                  size={{ sm: 174, md: 155 }}
                  className='mr-23px mr-md-32px mt-24px mt-md-40px'
                  style={{ width: 155 }}
                  src={logo10}
                />
              </div>
              <div>
                <LogoImage
                  size={{ sm: 197, md: 182 }}
                  className='mr-23px mr-md-32px mt-24px mt-md-40px'
                  style={{ width: 182 }}
                  src={logo11}
                />
              </div>
              <div>
                <LogoImage
                  size={{ sm: 55, md: 50 }}
                  className='mr-23px mr-md-32px mt-24px mt-md-40px'
                  style={{ width: 50 }}
                  src={logo12}
                />
              </div>
              <div>
                <LogoImage
                  size={{ sm: 192, md: 175 }}
                  className='mr-23px mr-md-32px mt-24px mt-md-40px'
                  src={logo13}
                />
              </div>
            </div>
            <Text size='xs' color='#838383' className='text-right'>
              ラボ型開発事業、及びHRプラットフォームの取引先様
            </Text>
          </div>
        </Container>
      </BackgroundContainer>
      <object style={{ width: '100vw' }} className='top-tri' data={toptri} />
    </div>
  );
};
export default Section2OurPartner;
