import { Button, Col, Row } from 'reactstrap';
import styled from 'styled-components';

import eclip from './images/eclip.svg';
import toptri from './images/top-tri.svg';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import FormikBoostrapTextField from 'components/Form/FormikBoostrapTextField';
import FormikBoostrapTextAreaField from 'components/Form/FormikBoostrapTextAreaField';
import { useState } from 'react';
import { MobilePadding } from 'utilities/mobile-pad';
import Section from 'utilities/section';
import { toastSuccess } from 'components/ToastAction/ToastAction';

const Backgound = styled.div`
  position: relative;
  z-index: 2;
  padding-bottom: 117px;
  .top-tri {
    width: 100%;
    position: relative;
    right: -2px;
    top: -1px;
  }
  .eclip-1 {
    position: absolute;
    width: 815px;
    top: -150px;
    left: -154px;
  }
  .eclip-2 {
    width: 383px;
    position: absolute;
    bottom: -150px;
    right: -154px;
  }
  background-color: #1890ff;
`;
const FormButton = styled(Button)`
  width: 300px;
  &.btn-primary {
    background-color: white;
    color: #1890ff;
    font-size: 24;
    font-weight: bold;
    padding: 18px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 12px;
    text-align: center;
    &:disabled {
      background-color: white;
      color: #1890ff;
    }
  }
  .text {
    flex: 1;
    text-align: center;
  }
  &.up {
    margin-top: -56px;
  }
`;
const Title = styled.div`
  font-size: 14px;
  color: white;
  text-align: center;
`;
const Des = styled.div`
  font-size: 40px;
  font-weight: 900;
  color: white;
  text-align: center;
  margin-bottom: 62px;
`;

const validationSchema = yup.object({
  email: yup
    .string()
    .email('メールアドレスは必須です')
    .required('メールアドレスは必須です'),
  name: yup.string().required('お名前は必須です'),
  companyName: yup.string().required('会社名は必須です'),
  message: yup.string().required('お問い合わせ内容は必須です'),
});

const TextField = styled(FormikBoostrapTextField)`
  input.form-control {
    border: none;
    border-radius: 6px;
  }
  .invalid-feedback {
    color: white;
  }
`;
const TextArea = styled(FormikBoostrapTextAreaField)`
  textarea.form-control {
    min-height: 200px;
    border: none;
    border-radius: 6px;
  }
  .invalid-feedback {
    color: white;
  }
`;
// const PhoneField = styled(FormikPhoneField)`
//   input.form-control {
//     border: none;
//     border-radius: 6px;
//     width: 100%;
//   }
//   .error-text {
//     color: white;
//   }
// `;
const AgreeCheck = styled.div`
  display: flex;
  align-items: center;
  margin: 64px;
  .aggree-text {
    color: white;
    font-weight: bold;
    font-size: 20px;
  }
  .form-check-input {
    margin-right: 12px;
  }
`;
function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const Section9 = () => {
  const [agree, setAgree] = useState(false);

  const submitForm = (data: any) => {
    return fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...data }),
    });
  };
  return (
    <Backgound>
      <object className='top-tri' data={toptri} />
      <object data={eclip} className='eclip-1' />
      <object data={eclip} className='eclip-2' />
      <Row className='justify-content-center'>
        <Col xs={12} md={6}>
          <Section id='inquiry' />
          <MobilePadding>
            <div>
              <Title>INQUIRY</Title>
              <Des>お問い合わせ</Des>
            </div>

            <Formik
              validationSchema={validationSchema}
              initialValues={{
                email: '',
                name: '',
                phone: '',
                companyName: '',
                message: '',
              }}
              onSubmit={(values, actions) => {
                submitForm(values).then(() => {
                  actions.resetForm();
                  setAgree(false);
                  toastSuccess({
                    title: 'Submit success',
                    message: 'Submit success',
                  });
                });
              }}>
              {(props) => (
                <Form
                  name='contact'
                  method='post'
                  action='/contact'
                  data-netlify='true'>
                  <TextField
                    className='mb-4'
                    name='companyName'
                    type='text'
                    label='会社名'
                  />
                  <TextField
                    className='mb-4'
                    name='name'
                    type='text'
                    label='ご担当者名'
                  />

                  <TextField
                    className='mb-4'
                    name='email'
                    type='text'
                    label='E-Mail'
                  />
                  {/* <PhoneField className='mb-4' name='phone' type='text' /> */}

                  <TextArea
                    className='mb-4'
                    name='message'
                    label='メッセージ'
                  />
                  <div className='d-flex flex-column align-items-center'>
                    <AgreeCheck>
                      <input
                        onChange={() => {
                          setAgree(!agree);
                        }}
                        className='form-check-input'
                        type='checkbox'
                        checked={agree}
                      />
                      <label className='aggree-text'>
                        個人情報の取り扱いに同意する
                      </label>
                    </AgreeCheck>
                    <FormButton color='primary' type='submit' disabled={!agree}>
                      送信する
                    </FormButton>
                  </div>
                </Form>
              )}
            </Formik>
          </MobilePadding>
        </Col>
      </Row>
    </Backgound>
  );
};

export default Section9;
