import styled, { css } from 'styled-components';

export const Text = styled.div<{
  size?: 'base' | 'xs' | 'sm' | '28px' | '40px';
  color?: 'white';
  weight?: 'bold';
}>`
  ${(props) =>
    props.size &&
    {
      xs: css`
        font-size: 12px;
      `,
      sm: css`
        font-size: 14px;
      `,
      base: css`
        font-size: 16px;
      `,
      '28px': css`
        font-size: 28px;
      `,
      '40px': css`
        font-size: 40px;
      `,
    }[props.size]}

  ${(props) =>
    props.color &&
    {
      white: css`
        color: #ffffff;
      `,
      '#838383': css`
        color: #838383;
      `,
    }[props.color]}

   ${(props) =>
    props.weight &&
    {
      bold: css`
        font-weight: bold;
      `,
    }[props.weight]}
`;
export default Text;
