import styled from 'styled-components';

const OutlineButton = styled.a`
  &.btn-secondary,
  &.btn-link {
    background: none;
    color: #1890ff;
    font-size: 16px;
    font-weight: bold;
    min-width: 300px;
    padding: 18px;
    border-radius: 8px;
    border: 2px solid #1890ff;
    text-decoration: none;
    &:hover {
      color: white;
      background-color: #1890ff;
    }
  }
  background: none;
`;
export default OutlineButton;
