import styled from 'styled-components';
import { device } from './constaint';

export const mobilePadding = `
  @media ${device.lg} {
    padding-left: 0;
    padding-right: 0;
  }
  padding-left: 12px;
  padding-right: 12px;
`;

export const MobilePadding = styled.div`
  ${mobilePadding}
`;
