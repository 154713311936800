import styled from 'styled-components';
import { device } from 'utilities/constaint';

export const ExtendNav = styled.nav`
  &.navbar {
    @media ${device.md} {
      padding-top: 20px;
    }
  }
`;
export const NavbarNav = styled.ul``;

export const NavLink = styled.a`
  color: #262626;
  padding: 0.5rem 0.8rem;
  text-decoration: none;
  display: block;
  font-size: 14px;
  &:hover,
  &:active,
  &:focus,
  &:target {
    color: #1890ff;
    &::after {
      content: '';
      background-color: #1890ff;
      width: 100%;
      height: 2px;
      display: block;
    }
  }
`;
