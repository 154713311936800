import { FC } from 'react';
import styled from 'styled-components';
const SectionStyle = styled.section`
  position: relative;
  top: -80px;
`;
const Section: FC<{ id: string }> = ({ id }) => (
  <SectionStyle id={id}></SectionStyle>
);

export default Section;
