import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { MobilePadding } from 'utilities/mobile-pad';
import img1 from './images/img1.png';
import img2 from './images/img2.png';
import img3 from './images/img3.png';
import bottomtri from './images/bottom-tri.svg';
import { Container } from 'utilities/container';
import { device } from 'utilities/constaint';

const Image1 = styled.img`
  margin-top: 12px;
  margin-bottom: 12px;
  max-width: 100%;
  align-self: center;
`;
const Image2 = styled.img`
  max-width: 100%;
  align-self: center;
`;

const ColWrap = styled(Col)`
  margin-top: 12px;
  display: grid;
`;
const Image3 = styled.img`
  max-width: 100%;
  transform: scale(1.15);
  margin-top: 50px;
  z-index: 10;
  margin-bottom: -60px;
  @media ${device.sm} {
    margin-top: 0;
    margin-bottom: 0;
    transform: scale(1);
  }
`;
const Background = styled.div`
  background-color: white;
  position: relative;
  .bottom-tri {
    position: relative;
    width: 100%;
    bottom: -5px;
  }
`;
const Section5 = () => (
  <Background>
    <Container className='container-xxl'>
      <MobilePadding>
        <Row className='justify-content-center'>
          <ColWrap sm={12} md={6} lg={6}>
            <Image1 src={img1} />
            <Image2 src={img2} />
          </ColWrap>
          <Col className='d-flex justify-content-center' sm={12} md={4} lg={3}>
            <Image3 src={img3} />
          </Col>
        </Row>
      </MobilePadding>
    </Container>
    <object
      style={{ position: 'relative', bottom: -6 }}
      className='bottom-tri'
      data={bottomtri}
    />
  </Background>
);

export default Section5;
