import { Link } from 'gatsby-plugin-react-i18next';
import { ExtendNav, NavbarNav, NavLink } from './HeaderStyle';
import SwitchLanguage from './SwitchLanguageButton';

import logo from './images/head-logo.png';
import tootleMenu from './images/toogle-menu.png';
import styled from 'styled-components';
import { useCallback, useState } from 'react';
import ModalMenu from './ModalMenu';

const ImageLogo = styled.img``;
const NavBarTootle = styled.button`
  &.navbar-toggler {
    border: none;
    &:focus {
      box-shadow: none;
    }
  }
`;
const FixHead = styled.header`
  background: white;
`;
const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const toggleMenu = useCallback(() => {
    setOpenMenu(!openMenu);
  }, [openMenu]);
  return (
    <FixHead className='fixed-top'>
      <ModalMenu isOpen={openMenu} toggle={toggleMenu} />
      <ExtendNav className='navbar navbar-expand-lg navbar-light container-xxl'>
        <Link className='navbar-brand' to='/'>
          <ImageLogo src={logo} />
        </Link>
        <NavBarTootle
          onClick={toggleMenu}
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarCollapse'
          aria-controls='navbarCollapse'
          aria-expanded='false'
          aria-label='Toggle navigation'>
          <img src={tootleMenu} />
        </NavBarTootle>
        <div
          className='collapse navbar-collapse justify-content-end'
          id='navbarCollapse'>
          <NavbarNav className='navbar-nav ml-auto'>
            <li className='nav-item active'>
              <NavLink href='#top'>トップ</NavLink>
            </li>
            <li className='nav-item active'>
              <NavLink href='#talents'>データベース</NavLink>
            </li>
            <li className='nav-item active'>
              <NavLink href='#partner'>弊社取引先実績</NavLink>
            </li>
            <li className='nav-item active'>
              <NavLink href='#strengths'>FREECの強み</NavLink>
            </li>
            <li className='nav-item active'>
              <NavLink href='#lab-development'>ラボ開発とは</NavLink>
            </li>
            <li className='nav-item active'>
              <NavLink href='#difference'>請負契約との違い</NavLink>
            </li>
            <li className='nav-item active'>
              <NavLink href='#flow'>ラボ開発の流れ</NavLink>
            </li>
            <li className='nav-item active'>
              <NavLink href='#expansion'>拡大</NavLink>
            </li>
            <li className='nav-item'>
              <NavLink href='#faq'>FAQ</NavLink>
            </li>
            <li className='nav-item'>
              <NavLink href='#inquiry'>お問い合わせ</NavLink>
            </li>
            <li className='nav-item'>
              <SwitchLanguage />
            </li>
          </NavbarNav>
        </div>
      </ExtendNav>
    </FixHead>
  );
};
export default Header;
