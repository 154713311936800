import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import svg1 from './images/svg1.svg';
import bottomtriangle from './images/bottom-triangle.svg';
import eclip from './images/eclip.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Section from 'utilities/section';
import { Container } from 'utilities/container';
import { Text } from 'components/Text/Text';
import { MobilePadding } from 'utilities/mobile-pad';
import { Table1, Table2, Table3 } from './Tables';
import { SliderChart } from './SliderChart';
const Backgound2 = styled.div`
  position: relative;
  z-index: 1;
  .green-background {
    background-color: #1890ff;
  }
  .top-tri {
    position: relative;
    width: 100%;
    bottom: 0;
  }
  .green-background {
    background-color: #1890ff;
  }
  .bottom-tri {
    margin-top: 40px;
    position: relative;
    width: 100%;
    margin-bottom: -6px;
  }
  .eclip-1 {
    position: absolute;
    bottom: -150px;
    left: -154px;
  }
  .eclip-2 {
    position: absolute;
    bottom: 150px;
    right: -400px;
    width: 815px;
  }
  .about-eclip {
    position: absolute;
    top: 220px;
    left: -183px;
    width: 483px;
  }
  .mb-100px {
    margin-bottom: 100px;
  }
`;

const SVG1 = styled.object`
  height: 475px;
`;

const Backgound2Mobile = styled.div`
  background-color: #1890ff;
  position: relative;
  z-index: 4;
  .bottom-tri {
    position: relative;
    width: 100%;
    margin-bottom: -6px;
  }
  .eclip-1 {
    width: 203px;
    position: absolute;
    bottom: -54px;
    left: -59px;
  }
  .eclip-2 {
    position: absolute;
    top: -139px;
    right: -63px;
    width: 250px;
  }
  .about-eclip {
    position: absolute;
    top: 512px;
    right: -122px;
    width: 300px;
  }
`;

const About = () => (
  <div className='d-flex flex-sm-column flex-md-row justify-content-center align-items-center row mb-100px '>
    <div
      className='d-flex flex-column justify-content-center'
      style={{ maxWidth: 500, margin: 'auto' }}>
      <Text size='28px' color='white' weight='bold'>
        freecracy について
      </Text>

      <Text className='mt-3' color='white'>
        freecracyはIT人材が不足する日本企業に対し、有効なソリューションである「ラボ型開発」を提供するITカンパニーです。
        日本・ベトナム双方のビジネス環境を熟知し、50万人のエンジニアデータを保持するfreecracyだからこそできる「無限×高水準のエンジニアリソース」で、理想の開発チーム・環境の構築を力強くサポートいたします。
      </Text>
    </div>

    <iframe
      className=''
      style={{ width: 600, height: 338 }}
      src='https://player.vimeo.com/video/715651240?title=0&amp;byline=0&amp;portrait=0&amp;pip=0&amp;playsinline=0'
      title='freecracyのラボ型開発の紹介動画'></iframe>
    <object className='about-eclip' data={eclip} />
  </div>
);

const SUM = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 12px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  .right {
    text-align: right;
  }
`;
const Section2 = () => (
  <>
    <Backgound2 className=' d-none d-sm-none d-lg-block'>
      <div style={{ paddingTop: 50 }} className='green-background'>
        <Section id='talents'></Section>
        <Container className='container-xxl'>
          <About />
        </Container>

        <div className='d-flex justify-content-center'>
          <div style={{ display: 'flex' }}>
            <div>
              <SVG1 data={svg1} style={{ marginTop: 51 }} />
              <Row className='justify-content-center'>
                <Col xs='10'>
                  <div
                    className='d-flex'
                    style={{ marginRight: 44, marginTop: 24 }}>
                    <SUM>
                      <div className='right'>総登録数：</div>
                      <div> 154.185 スキル</div>
                      <div className='right'> 平均経験年数：</div>
                      <div> 3.9 年</div>
                    </SUM>
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <Table1 />
              <Table2 style={{ marginTop: 25 }} />
              <Table3 style={{ marginTop: 30 }} />
            </div>
          </div>
        </div>
        <object className='bottom-tri' data={bottomtriangle} />
        <object className='eclip-1' data={eclip} />
        <object className='eclip-2' data={eclip} />
      </div>
    </Backgound2>

    <Backgound2Mobile className='d-lg-none'>
      <Container className='container-xxl' style={{ paddingTop: 100 }}>
        <MobilePadding>
          <About />
        </MobilePadding>
      </Container>
      <SliderChart />
      <Row className='justify-content-center'>
        <Col xs='10'>
          <div className='d-flex'>
            <SUM>
              <div className='right'>総登録数：</div>
              <div> 154.185 スキル</div>
              <div className='right'>平均経験年数：</div>
              <div> 3.9 年</div>
            </SUM>
          </div>
        </Col>
      </Row>
      <object className='bottom-tri' data={bottomtriangle} />
      <object className='eclip-1' data={eclip} />
      <object className='eclip-2' data={eclip} />
    </Backgound2Mobile>
  </>
);

export default Section2;
