import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { MT } from 'utilities/margin';
import { mobilePadding } from 'utilities/mobile-pad';
import Section from 'utilities/section';

import image1 from './images/img-1.jpg';
import image2 from './images/img-2.jpg';
import image3 from './images/img-3.jpg';
import image4 from './images/img-4.png';

const TextArea = styled.div`
  ${mobilePadding}
  max-width: 500px;
  text-align: center;
`;
const WhyChooseUs = styled.div`
  color: #838383;
`;
const BigText = styled.h2`
  font-size: 40px;
  font-weight: 900;
  line-height: 54px;
`;

const ImageItem = styled.img`
  max-width: 100%;
  margin-bottom: 62px;
  &.left {
    border-radius: 0 16px 16px 0;
  }
  &.right {
    border-radius: 16px 0 0 16px;
  }
`;
const ItemTitle = styled.div`
  color: #1890ff;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 16px;
`;
const ItemText = styled.div`
  font-size: 16px;
`;
const ItemRow = styled(Row)`
  ${mobilePadding}
  margin-bottom: 62px;
`;
const TextNumber = styled.div`
  color: white;
  font-size: 182px;
  margin-bottom: -127px;
  margin-top: -50px;
  text-align: center;
`;
const TextGroup = styled.div`
  max-width: 500px;
`;

const Section3 = () => (
  <>
    <Section id='strengths'></Section>
    <MT />
    <TextArea className='m-auto'>
      <MT />
      <div>
        <WhyChooseUs id='why_choose_us'>
          <span>WHY CHOOSE US?</span>
        </WhyChooseUs>
        <BigText>
          <span>なぜ</span>
          <span style={{ color: 'rgba(24,144,255,1)' }}>freeC</span>
          <span>がラボ開発において 圧倒的に良いのか</span>
        </BigText>
      </div>
    </TextArea>
    <MT />
    <ItemRow>
      <Col sm={12} md={6}>
        <ImageItem className='left' src={image1} />
      </Col>
      <Col sm={12} md={6} lg={4}>
        <TextGroup>
          <TextNumber id='n_1'>
            <span>01</span>
          </TextNumber>
          <div id='Group_22'>
            <ItemTitle id='Text'>
              <span>圧倒的なエンジニアデータベース</span>
            </ItemTitle>
            <ItemText id='freeC__'>
              <span>
                上述の通り、freeCにはハイスペックなエンジニアプールが蓄積されており、現在も圧倒的な勢いで増え続けています。
                <br />
                それによって、非常に素早く、質の高いエンジニアだけを抽出し、ご紹介することが可能です。
                <br />
                迅速にチームを作りたいお客様は是非当社をご用命下さい。
              </span>
            </ItemText>
          </div>
        </TextGroup>
      </Col>
    </ItemRow>

    <ItemRow>
      <Col sm={12} md={{ size: 6, order: 'last' }}>
        <ImageItem className='right' src={image2} />
      </Col>
      <Col sm={12} md={6} className='d-flex justify-content-end'>
        <TextGroup id='Group_18'>
          <TextNumber>
            <span>02</span>
          </TextNumber>
          <ItemTitle>
            <span>80名以上のエンジニア専門ヘッドハンター</span>
          </ItemTitle>
          <ItemText>
            <span>
              弊社は社内に80名以上のエンジニアを専門とした採用チームがおり、プラットフォームからAIがリコメンドした候補者を
              <br />
              この採用チームが素早く、且つ丁寧にアプローチ、採用することにより、ミスマッチの少なく素早い採用が可能となります。
              <br />
              フロント、バックエンド、プロジェクトマネージャー、ブリッジSE、コミュニケーターそれぞれの専門チームが存在します。
            </span>
          </ItemText>
        </TextGroup>
      </Col>
    </ItemRow>
    <ItemRow>
      <Col sm={12} md={6}>
        <ImageItem className='left' src={image3} />
      </Col>
      <Col sm={12} md={6}>
        <TextGroup id='Group_20'>
          <TextNumber>
            <span>03</span>
          </TextNumber>
          <ItemTitle>
            <span>弊社自身がプロダクトカンパニーである </span>
          </ItemTitle>
          <ItemText>
            <span>
              弊社自身が20名以上のエンジニアを雇用し、自社プロダクトを開発している企業である為、ただ開発を受けるだけの企業ではなく、
              <br />
              お客様のペインやニーズを深く理解し、提案させて頂けることが弊社の強みです。
              <br />
              安いだけではなく、質の高いエンジニアと最高のプロダクトを創ることにコミットいたします。
            </span>
          </ItemText>
        </TextGroup>
      </Col>
    </ItemRow>
    <ItemRow>
      <Col sm={12} md={{ size: 6, order: 'last' }}>
        <ImageItem className='right' src={image4} />
      </Col>
      <Col sm={12} md={6} className='d-flex justify-content-end'>
        <TextGroup id='Group_19'>
          <TextNumber>
            <span>04</span>
          </TextNumber>
          <ItemTitle>
            <span>ワンストップで必要となる全てのサービス提供が可能</span>
          </ItemTitle>
          <ItemText>
            <span>
              弊社では、ベトナム市場において一流と言われる会計事務所、法務事務所とも深い繋がりがあり、開発だけではなく、
              <br />
              ライセンス取得を含む会社設立や会計、法務相談などワンストップでソリューションを提供させて頂くことが可能です。
            </span>
          </ItemText>
        </TextGroup>
      </Col>
    </ItemRow>
  </>
);

export default Section3;
