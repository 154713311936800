import { useCallback } from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import backtop from './BackTopButton.svg';
const BackToTopButtonStyle = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 10;
  right: 10px;
  cursor: pointer;

  &.fade-in {
    animation: fadeIn ease 2s;
  }
  &.fade-out {
    opacity: 0;
    animation: fadeOut ease 2s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
const BackToTopButton = () => {
  const [show, setShow] = useState(false);
  const scrollTracking = useCallback(() => {
    if (window.scrollY > 50) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, []);
  useEffect(() => {
    window.addEventListener('scroll', scrollTracking);
    return () => {
      window.removeEventListener('scroll', scrollTracking);
    };
  }, []);
  console;
  return (
    <BackToTopButtonStyle className={show ? 'fade-in' : 'fade-out'}>
      <a href='#top'>
        <img onClick={() => {}} src={backtop} />
      </a>
    </BackToTopButtonStyle>
  );
};

export default BackToTopButton;
