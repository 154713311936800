import { graphql } from 'gatsby';
import Home from 'modules/Home/Home';

const IndexPage = () => {
  return <Home />;
};
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["page-home"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export default IndexPage;
